// dataSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"; // You might need to install axios: npm install axios
import { baseUrl } from "config";

const getProduct = "/getItemNames";
// Define the initial state
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// Define the thunk for fetching data from the API
export const fetchData = createAsyncThunk("data/fetchData", async () => {
  try {
    const response = await axios.get(baseUrl + getProduct, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    // Handle errors
    throw error;
  }
});

// Define the data slice
const productDataSlice = createSlice({
  name: "ProductsData",
  initialState,
  reducers: {
    // Additional reducers can be defined here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.status = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = "failed to fetch product";
        state.error = action.error.message;
      });
  },
});

export default productDataSlice.reducer;
