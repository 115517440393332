import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import SalesRoute from './SalesRoute';
import PurchaseRoute from './PurchaseRoute';
import AccountRoutes from './AccountRoutes';
import ReportsRoutes from './ReportsRoutes';
import Others from './Others';
// import SettingsRoutes from './SettingsRoutes';
import PrinterRoutes from './PrinterRoutes';

// import ReportRoutes from './ReportRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes,
        AuthenticationRoutes,
        SalesRoute,
        PurchaseRoute,
        AccountRoutes,
        ReportsRoutes,
        Others,
        // SettingsRoutes,
        PrinterRoutes
    ]);
    // return useRoutes([MainRoutes, AuthenticationRoutes, SalesRoute]);
}
