// conversionUtils.js

export const stringToHex = (str) => {
    let hex = '';

    for (let i = 0; i < str.length; i++) {
        const charCode = str.charCodeAt(i);
        const hexValue = charCode.toString(16);

        // Pad with zeros to ensure two-digit representation
        hex += hexValue.padStart(2, '0');
    }
    return hex;
};

export const hexToBase64 = (hexString) => {
    const bytes = [];
    for (let i = 0; i < hexString.length; i += 2) {
        bytes.push(parseInt(hexString.substr(i, 2), 16));
    }
    const uint8Array = new Uint8Array(bytes);
    const base64String = btoa(String.fromCharCode.apply(null, uint8Array));
    return base64String;
};
