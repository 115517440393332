import { createSlice } from "@reduxjs/toolkit";

const tableDataSlice = createSlice({
  name: "tableData",
  initialState: [],
  reducers: {
    addItemToTableData: (state, action) => {
      state.push(action.payload);
    },
    updateQtyAndTotal: (state, action) => {
      const { index, qty, taxAmt, total } = action.payload;
      state[index].qty = qty;
      state[index].taxAmt = taxAmt;
      state[index].total = total;
    },
    updateRateAndTotal: (state, action) => {
      const { index, Rate1, qty, taxAmt } = action.payload;
      state[index].Rate1 = Rate1;
      state[index].total = Rate1 * qty + taxAmt;
      state[index].taxAmt = taxAmt;
    },
    deleteItemFromTableData: (state, action) => {
      const index = state.findIndex(
        (item) => item.ItemNo === action.payload.ItemNo
      );
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    clearTableData: (state, action) => {
      return [];
    },
    // New reducer to replace the array with new data
    replaceTableData: (state, action) => {
      return action.payload; // Set the state to the new data
    },
  },
});

export const {
  addItemToTableData,
  updateRateAndTotal,
  updateQtyAndTotal,
  deleteItemFromTableData,
  clearTableData,
  replaceTableData,
} = tableDataSlice.actions;
export default tableDataSlice.reducer;
