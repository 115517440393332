import { useRef, useEffect } from "react";
import { Box, Divider, Container, Stack, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useLocation } from "react-router";
// import axios from "axios";
// import { baseUrl } from "config";
import numberToWords from "number-to-words";
import dayjs from "dayjs";
import QrCodeGenerator from "views/commonComponents/QrCode/QrCodeGenerator";
import { useSelector } from "react-redux";

function ThermalPurchase() {
  const location = useLocation();
  const { data, tableData, qrData } = location.state;
  const componentRef = useRef();
  const company = useSelector((state) => state.common.companyInfo);
  const navigate = useNavigate();
  const decimalCount = useSelector((state) => state.common.decimalCount);
  const primaryCurrency = useSelector((state) => state.common.primaryCurrency);
  const subCurrency = useSelector((state) => state.common.subCurrency);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // This will be called after the printing is completed
      navigate(-2); // Navigate back to the previous page
    },
  });
  // useEffect(() => {
  //   axios
  //     .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
  //     .then((res) => {
  //       setCompany(res.data[0]);
  //     });
  //   handlePrint();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    handlePrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);
  // function convertNumberToWordsWithCurrency(number, currency) {
  //   const words = numberToWords.toWords(number);
  //   return `${words} ${currency}`;
  // }
  function convertNumberToWordsWithCurrency(number) {
    const paisaAmount = (number * 100) % 100;
    const words = numberToWords.toWords(Math.floor(number));
    const paisaInWords = numberToWords.toWords(paisaAmount);
    return `${words} ${primaryCurrency} and  ${paisaInWords} ${subCurrency}`;
  }

  const amount = data.NTot;
  const result = convertNumberToWordsWithCurrency(amount);

  return (
    <div>
      {/* <a href="#" onClick={handlePrint}>
        Print this out!
      </a> */}
      <Box width={384} ref={componentRef} sx={{ background: "white" }}>
        {/* Header section */}
        {/* <Container disableGutters sx={{ display: 'flex' }}>
                    <small>GSTIN:</small>
                    <small>1234567890</small>
                </Container> */}
        <Container
          className="vt_print_ther_head"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingBottom: "0.8rem",
          }}
        >
          <h2 style={{ margin: 0 }}>{company.Name}</h2>
          <p style={{ margin: 0 }}>{company.Add1} </p>
          <p style={{ margin: 0 }}>{company.Ph} </p>
        </Container>
        <Container disableGutters>
          <Divider />
          <Container
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <p style={{ margin: "1px", fontSize: "12px" }}>
              Ret. No: {data.InvoiceNo}
            </p>
            <p style={{ margin: "1px", fontSize: "12px" }}>
              DATE: <span>{dayjs(data.InvoiceDat).format("DD/MM/YYYY")}</span>
            </p>
          </Container>
          <p style={{ margin: "1px", fontSize: "12px" }}>
            Customer Name: <span>{data.PartyName}</span>
          </p>
          <Container
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <p style={{ margin: "1px", fontSize: "12px" }}>
              State: <span>KERALA</span>
            </p>
            <p style={{ margin: "1px", fontSize: "12px" }}>
              Veh: <span>0</span>
            </p>
          </Container>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  SI
                </th>
                <th
                  style={{
                    textAlign: "left",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  Item
                </th>
                <th
                  style={{
                    textAlign: "right",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  Qty
                </th>
                <th
                  style={{
                    textAlign: "right",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  Price
                </th>
                <th
                  style={{
                    textAlign: "right",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  NetValue
                </th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid black", width: "100%" }}>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left" }}>{index + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    {item.ItemNameTextField}
                  </td>
                  <td style={{ textAlign: "right" }}>{item.qty}</td>
                  <td style={{ textAlign: "right" }}>{item.Cost}</td>
                  <td style={{ textAlign: "right" }}>{item.netValue}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr style={{ borderTop: "dashed 1px #8c8b8b", margin: 0 }} />
          <table>
            <tbody>
              <Container
                disableGutters
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0.4rem",
                }}
              >
                <small style={{ textAlign: "center" }}>
                  Total Qty: <span>{data.TotItWiseQty}</span>
                </small>
              </Container>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    textAlign: "end",
                  }}
                >
                  Total Amount :{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {data.Total.toFixed(decimalCount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    textAlign: "end",
                  }}
                >
                  Vat Amount :{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {data.Tax.toFixed(decimalCount)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    textAlign: "end",
                  }}
                >
                  Grand Total :{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {data.NTot.toFixed(decimalCount)}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            style={{ paddingLeft: "2px", paddingBottom: "5px", width: "30%" }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography>Cash Paid</Typography>
              <Typography>: 0</Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography> Net Balance :</Typography>
              <Typography>: 0</Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography> Balance Amt :</Typography>
              <Typography>: 0</Typography>
            </Stack>
          </div>
          <hr style={{ borderTop: "dashed 1px #8c8b8b", margin: 0 }} />
          <hr style={{ borderTop: "dashed 1px #8c8b8b", margin: 0 }} />
          <Container
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "0.4rem",
            }}
          >
            <small style={{ textAlign: "center", fontWeight: "bold" }}>
              Amount In Words : <span>{result}</span>
            </small>
          </Container>
          <hr style={{ borderTop: "dashed 1px #8c8b8b", margin: 0 }} />
          <hr style={{ borderTop: "dashed 1px #8c8b8b", margin: 0 }} />
        </Container>
        <Stack direction="row" justifyContent="center">
          {/* <Typography sx={{ paddingBlock: '0.5rem' }} variant="caption" gutterBottom>
                        Authorised Signatory
                    </Typography> */}
          {/* <Qr */}
          {/* <img
                        src="https://www.freepnglogos.com/uploads/qr-code-png/qr-code-code-label-transparent-png-svg-vector-21.png"
                        alt="qrCode"
                        width={'80px'}
                    /> */}
          <Box width={100}>
            <QrCodeGenerator qrData={qrData} />
          </Box>
        </Stack>
      </Box>
    </div>
  );
}

export default ThermalPurchase;
