// material-ui

import { useSelector } from "react-redux";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const companyInfo = useSelector((state) => state.common.companyInfo);
  //  console.log(JSON.parse(companyInfo.logo).img);
  console.log(typeof companyInfo.logo);
  const logo = companyInfo.logo ? JSON.parse(companyInfo.logo)?.img : null;
  return <img src={logo} alt="Berry" width={64} height={64} />;
};

export default Logo;
