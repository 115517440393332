// import { lazy } from 'react';
import SalesLayOut from "views/Sales-Sections/SalesLayOut";
// import Loadable from 'ui-component/Loadable';
// import ThermalPrint from 'views/PrintLayouts/ThermalPrint';
import BatchPrint from "views/PrintTypes/BatchPrint";
// const BatchPrint = Loadable(lazy(() => import('views/others/BatchPrint')));
// const ThermalPrint = Loadable(lazy(() => import('views/others/ThermalPrint')));
// const A4Print = Loadable(lazy(() => import('views/others/A4Print')));
import Print from "views/print/Print";
import A4Print from "views/PrintTypes/A4Print";
import ThermalPrint from "views/PrintTypes/ThermalPrint";
import A4PurchasePrint from "views/PrintTypes/A4PurchasePrint";
import ThermalPurchase from "views/PrintTypes/ThermalPurchase";
import A4PrintTaxVAt from "views/PrintTypes/TaxVat/A4PrintTaxVAt";
import ThermalPrintTaxVat from "views/PrintTypes/TaxVat/ThermalPrintTaxVat";

const PrinterRoutes = {
  path: "printer",
  element: <SalesLayOut />,
  children: [
    {
      path: "printerCheck",
      element: <Print />,
    },
    {
      path: "thermalPrint",
      element: <ThermalPrint />,
    },
    {
      path: "a4",
      element: <A4Print />,
    },
    {
      path: "batchPrint",
      element: <BatchPrint />,
    },
    {
      path: "purchaseA4",
      element: <A4PurchasePrint />,
    },
    {
      path: "purchaseThermal",
      element: <ThermalPurchase />,
    },
    {
      path: "lazerA4PrintTaxVat",
      element: <A4PrintTaxVAt />,
    },
    {
      path: "ThermalPrintTaxVat",
      element: <ThermalPrintTaxVat/>,
    },
  ],
};
export default PrinterRoutes;
