import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const purchaseReturnHeaderSlice = createSlice({
    name: 'purchaseReturnHeader',
    initialState: {
        customer: null,
        contact: 0,
        prevNo: 0,
        BatchNo: 0,
        returnNo: null,
        taxNo: 0,
        date: dayjs(),
        outlet: null,
        editMode: false
    },
    reducers: {
        updateSelected: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        resetState: () => {
            // Reset the state to its initial/default values
            return {
                customer: null,
                contact: 0,
                prevNo: 0,
                BatchNo: 0,
                returnNo: null,
                taxNo: null,
                date: dayjs(),
                outlet: null
            };
        }
    }
});

export const { updateSelected, resetState } = purchaseReturnHeaderSlice.actions;
export default purchaseReturnHeaderSlice.reducer;
