import { useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import { useSelector, useDispatch } from "react-redux";
import {
  setDiscPer,
  setDiscAmt,
  setTaxPer,
  setOther,
  setDiscAmtByPer,
  setTaxAmt,
  setTotal,
  setNetTotal,
} from "../slice/discAndTaxSlice";

const Calculation = () => {
  const dispatch = useDispatch();

  // Selecting data from Redux state
  const tableData = useSelector((state) => state.tableData);
  const discPer = useSelector((state) => state.discAndTax.discPer);
  const discAmtByPer = useSelector((state) => state.discAndTax.discAmtByPer);
  const discAmt = useSelector((state) => state.discAndTax.discAmt);
  const taxPer = useSelector((state) => state.discAndTax.taxPer);
  const taxAmt = useSelector((state) => state.discAndTax.taxAmt);
  const other = useSelector((state) => state.discAndTax.other);
  const total = useSelector((state) => state.discAndTax.total);
  const netTotal = useSelector((state) => state.discAndTax.netTotal);

  // Calculate the total
  const totalCalc = tableData.reduce((acc, cur) => {
    return acc + cur.total;
  }, 0);

  // Use an effect to update total and netTotal when dependencies change
  useEffect(() => {
    dispatch(setTotal(totalCalc));
    dispatch(setDiscAmtByPer((discPer / 100) * total));

    // Calculate the netTotal
    const netTotalCalc =
      totalCalc +
      (taxPer / 100) * total -
      (discPer / 100) * total -
      discAmt +
      other;
    dispatch(setNetTotal(netTotalCalc));
  }, [totalCalc, discPer, taxPer, discAmt, other, tableData, dispatch, total]);
  useEffect(() => {
    const discounted = (discPer / 100) * total;
    dispatch(setDiscAmtByPer(discounted));
  }, [discPer, total, dispatch]);

  // Handle changes in discount percentage
  const handleDiscountPerc = (e) => {
    dispatch(setDiscPer(e.target.value));

    // Calculate the discount amount by percentage
    // const discounted = (e.target.value / 100) * total;

    // // Dispatch the discount amount by percentage
    // dispatch(setDiscAmtByPer(discounted));
  };

  // Handle changes in tax percentage
  const handleTaxPerc = (e) => {
    dispatch(setTaxPer(e.target.value));

    // Calculate the tax amount
    const calculatedTaxAmt = (e.target.value / 100) * total;

    // Dispatch the tax amount
    dispatch(setTaxAmt(calculatedTaxAmt));
  };

  const handleOther = (e) => {
    // const otherChrg
    dispatch(setOther(parseInt(e.target.value)));
    // const newNetTotal = parseFloat(e.target.value) + netTotal;
    // dispatch(setNetTotal(newNetTotal));
  };
  const handleDiscAmount = (e) => {
    dispatch(setDiscAmt(e.target.value));
  };
  return (
    <Box component={Paper} elevation={3} sx={{ p: 1.5 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Discount %"
            type="number"
            fullWidth
            size="small"
            value={discPer}
            onChange={handleDiscountPerc}
            inputProps={{
              min: 0,
              style: { textAlign: "end", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon sx={{ fontSize: 16 }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="number"
            label="Tax %"
            fullWidth
            size="small"
            onChange={handleTaxPerc}
            value={taxPer}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PercentIcon sx={{ fontSize: 16 }} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              min: 0,
              style: { textAlign: "end", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="number"
            label="Discount Amount"
            fullWidth
            size="small"
            onChange={(e) => {
              handleDiscAmount(e);
            }}
            value={discAmt}
            inputProps={{
              min: 0,
              style: { textAlign: "end", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="number"
            label="Discount % amt"
            fullWidth
            size="small"
            value={
              typeof discAmtByPer === "number" ? discAmtByPer.toFixed(3) : 0
            }
            inputProps={{
              min: 0,
              readOnly: true,
              style: { textAlign: "center", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            type="number"
            label="Tax Amount"
            fullWidth
            size="small"
            value={taxAmt.toFixed(3)}
            inputProps={{
              min: 0,
              style: { textAlign: "center", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="number"
            label="Other charges"
            fullWidth
            size="small"
            onChange={handleOther}
            value={parseInt(other)}
            inputProps={{
              min: 0,
              style: { textAlign: "end", fontWeight: "bold" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <Stack direction="row">
                        <Typography variant="h5" gutterBottom>
                            Balance :
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            {posHeader.balance}
                        </Typography>
                    </Stack> */}
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4" gutterBottom>
              Subtotal :
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ textAlign: "right" }}>
              {total.toFixed(3)}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Divider />
          <Divider />
        </Grid>
        <Grid item xs={6}>
          {/* <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">Net Total</Typography>
                        <Typography variant="h4" sx={{ textAlign: 'right' }}>
                            {netTotal.toFixed(3)}
                        </Typography>
                    </Stack> */}
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Net Total</Typography>
            <Typography variant="h4" sx={{ textAlign: "right" }}>
              {netTotal.toFixed(3)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calculation;
