import React, { useRef, useEffect } from "react";
import { Container, Typography, Stack, Divider, Box } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import numberToWords from "number-to-words";
import { useNavigate, useLocation } from "react-router";
// import axios from "axios";
// import { baseUrl } from "config";
import dayjs from "dayjs";
import QrCodeGenerator from "views/commonComponents/QrCode/QrCodeGenerator";
import { useSelector } from "react-redux";
function A4PurchasePrint() {
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const { data, tableData, qrData } = location.state;
  const company = useSelector((state) => state.common.companyInfo);
  const companyLogo = company.logo ? JSON.parse(company.logo)?.img : null;
  const decimalCount = useSelector((state) => state.common.decimalCount);
  const primaryCurrency = useSelector((state) => state.common.primaryCurrency);
  const subCurrency = useSelector((state) => state.common.subCurrency);

  // const [isQrCodeGenerated, setIsQrCodeGenerated] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
  //     .then((res) => {
  //       setCompany(res.data[0]);
  //     });
  // }, []);

  // const handleQrCodeGenerated = () => {
  //   setIsQrCodeGenerated(true);
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      navigate(-2);
    },
  });

  useEffect(() => {
    if (company) {
     // this timeout is used to show qr code rendering in print modal
     const timeoutId = setTimeout(() => {
      handlePrint();
    }, 100);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  // function convertNumberToWordsWithCurrency(number, currency) {
  //   const words = numberToWords.toWords(number);
  //   return `${words} ${currency}`;
  // }
  function convertNumberToWordsWithCurrency(number) {
    const paisaAmount = (number * 100) % 100;
    const words = numberToWords.toWords(Math.floor(number));
    const paisaInWords = numberToWords.toWords(paisaAmount);
    return `${words} ${primaryCurrency} and  ${paisaInWords} ${subCurrency}`;
  }

  const amount = data.NTot;
  const currency = "Saudi Riyal";
  const result = convertNumberToWordsWithCurrency(amount, currency);

  const generateSections = (tableData) => {
    const sections = [];
    const itemsPerPage = 9;

    sections.push(renderSection(tableData.slice(0, itemsPerPage), 0));

    for (let i = itemsPerPage; i < tableData.length; i += itemsPerPage) {
      const sectionItems = tableData.slice(i, i + itemsPerPage);
      sections.push(renderSection(sectionItems, i));
    }

    return sections;
  };

  const renderSection = (sectionItems, sectionIndex) => {
    return (
      <div
        key={`section-${sectionIndex}`}
        style={{
          marginTop: sectionIndex !== 0 ? "1rem" : "0",
          pageBreakBefore: "always",
          border: "0px ",
        }}
      >
        <div style={{ border: "1px solid black" }}>
          <Stack direction="row" sx={{ height: "15.6rem" }}>
            <div
              style={{
                width: "45%",
                borderRight: "1px solid black",
                height: "15.6rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div style={{ width: "9.3rem", height: "30%", margin: "1rem" }}>
                <img
                  // src={company.logo}
                  src={companyLogo}
                  style={{
                    objectFit: "contain",
                    height: "100%",
                    width: "100%",
                  }}
                  alt="s"
                />
              </div>

              <Container disableGutters sx={{ padding: "0.3rem" }}>
                <Typography variant="h4">{company.Name}</Typography>
                <Typography>{company.Add1}</Typography>
                <Typography>{(company.city_name, company.Place)}</Typography>
                <Typography> {company.Country}</Typography>
                <Typography> Zipcode{company.Postal}</Typography>
                <Typography>Ph : {company.Ph}</Typography>
                <Typography>VAT: {company.Add2}</Typography>
                <Typography> {company.Web}</Typography>
                <Typography> {company.EMail}</Typography>
              </Container>
            </div>
            <Stack direction="column" sx={{ width: "55%", height: "15.6rem" }}>
              <Typography align="center" variant="h4" sx={{ height: "1.5rem" }}>
                Tax Invoice
              </Typography>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody style={{ padding: "10px" }}>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid #8c8b8b",
                        borderTop: "1px solid #8c8b8b",
                        height: "1.5rem",
                      }}
                    >
                      <Typography>
                        {data.BillNo
                          ? `Invoice No: ${data.BillNo}`
                          : `Return No: ${data.RetNo}`}
                      </Typography>
                    </td>
                    <td
                      style={{
                        borderTop: "1px solid #8c8b8b",
                        height: "1.5rem",
                      }}
                    >
                      <Typography>Original for Recipient</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid #8c8b8b",
                        height: "1.5rem",
                      }}
                    >
                      <Typography>
                        Invoice Date:{" "}
                        <b>{dayjs(data.BillDate).format("DD-MM-YYYY")}</b>
                      </Typography>
                    </td>
                    <td style={{ height: "1.5rem" }}>
                      <Typography>Duplicate for Transporter</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ borderRight: "1px solid #8c8b8b" }}>
                      <Typography>
                        State Code: <b>32</b>
                      </Typography>
                    </td>
                    <td style={{}}>
                      <Typography>Triplicate for Supplier</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid #8c8b8b",
                        borderBottom: "1px solid #8c8b8b",
                      }}
                    >
                      <Typography>
                        Client ID: <b>0</b>
                      </Typography>
                    </td>
                    <td style={{ borderBottom: "1px solid #8c8b8b" }}>
                      <Typography>
                        Vehicle No: <b>0</b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={0}
                sx={{ height: "100%" }}
              >
                <Stack direction="column" alignItems={"start"}>
                  <Typography>
                    <b style={{ display: "block" }}>To :{data.BillPartyName}</b>{" "}
                    {data.CashP}
                  </Typography>
                  <Typography>PH: 1234567234 </Typography>
                  <Typography>
                    <b>VAT NO:</b>1234567234{" "}
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="center">
                  <Box width={120}>
                    <QrCodeGenerator qrData={qrData} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <div style={{ height: "31.8rem" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    SI NO
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      border: "1px solid #8c8b8b",
                      width: "30%",
                      padding: "0.6rem",
                    }}
                  >
                    Description
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Qty
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Unit
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Pack
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Total
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    Tax %
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    TaxAmnt
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      border: "1px solid #8c8b8b",
                      padding: "0.6rem",
                    }}
                  >
                    NetAmount
                  </th>
                </tr>
              </thead>
              <tbody>
                {sectionItems.map((prod, index) => (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "center",
                        padding: "1rem",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "left",
                        borderRight: "1px solid #8c8b8b",
                        padding: "5px",
                      }}
                    >
                      {prod.ItemNameTextField}
                    </td>
                    {/* <td style={{ textAlign: 'center', borderRight: '1px solid #8c8b8b' }}>{prod.ItemNo}</td> */}
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.qty}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.Cost}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.Typ}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.Pack}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.total}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.Tax1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        borderRight: "1px solid #8c8b8b",
                      }}
                    >
                      {prod.taxAmt}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRight: "1px solid #8c8b8b",
                        padding: "5px",
                      }}
                    >
                      {prod.netValue}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Divider sx={{ borderBottom: "0.5px solid grey" }} />
          </div>

          <Stack direction="row">
            <Stack
              direction="column"
              sx={{ width: "60%", borderRight: "1px solid black" }}
            >
              <Container disableGutters sx={{ padding: "0.3rem" }}>
                <Typography> Amount In Words :</Typography>
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {result}
                </Typography>
              </Container>
              <Container
                disableGutters
                sx={{ borderTop: "1px solid black", padding: "0.3rem" }}
              >
                <Typography> Bank Details:-</Typography>
                <Typography variant="h5">
                  {" "}
                  A/c Name: CRAFI INNOVATIONS PRIVATE LIMITED
                </Typography>
                <Typography variant="h5"> A/c No: 50200080579062</Typography>
                <Typography variant="h5"> Branch: CHEMMAD</Typography>
                <Typography variant="h5"> IFSC: HDFC0004017</Typography>
                {/* <Typography sx={{ marginTop: 3 }}> UPI: 9544606340</Typography> */}
              </Container>
              <Container
                disableGutters
                sx={{ borderTop: "1px solid black", padding: "0.8rem 0.3rem" }}
              >
                <Typography>Declaration</Typography>
                We declare that this invoice shows the actual price of the goods
                described and that all particulars are true and correct.
              </Container>
            </Stack>
            <Stack direction="column" sx={{ width: "40%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "1rem",
                }}
              >
                <Typography variant="h5">Subtotal :</Typography>
                <Typography variant="h5">
                  {" "}
                  {data.Total.toFixed(decimalCount)}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "1rem",
                }}
              >
                <Typography variant="h5"> VAT % : </Typography>
                <Typography variant="h5">
                  {" "}
                  {data.Tax.toFixed(decimalCount)}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderTop: "1px solid black",
                  height: "2rem",
                  paddingLeft: "1rem",
                }}
              >
                <Typography variant="h5"> Round Off : </Typography>
                <Typography variant="h5"> {data.BillPackageField}</Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBlock: "1px solid black",
                  height: "2rem",
                  paddingLeft: "1rem",
                }}
              >
                <Typography variant="h5"> Net Total : </Typography>
                <Typography variant="h5">
                  {" "}
                  {data.NTot.toFixed(decimalCount)}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                justifyContent="space-between"
                sx={{ height: "5rem" }}
              >
                <Typography
                  align="center"
                  variant="h6"
                  sx={{ marginLeft: "auto" }}
                >
                  E&OE
                </Typography>
                <Typography align="center" variant="h6">
                  For {company.Name}
                </Typography>
                <Typography align="center" variant="h6">
                  Authorised Signatory
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  };

  const sections = generateSections(tableData);

  return (
    <div>
      {/* {isQrCodeGenerated && (
        <a href="#" onClick={handlePrint}>
          Print this out!
        </a>
      )} */}
      <div
        ref={componentRef}
        style={{
          background: "white",
          border: "1px solid black",
          margin: "1rem",
          height: "auto",
        }}
      >
        {sections}
      </div>
    </div>
  );
}

export default A4PurchasePrint;
