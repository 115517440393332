import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SalesHeader from './components/SalesHeader';
import TableSection from './components/TableSection';
import Footer from './components/Footer';
import { handleKeyDown } from 'views/utilities/enterFunctionForPOS';
const CardWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[700],
    minHeight: '90vh',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
        content: '""',
        zIndex: 0,
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.grey[600]} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -100
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.orange.dark} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -140,
        right: -60
    }
}));

function SalesReturn() {
    return (
        <CardWrapper onKeyDown={handleKeyDown}>
            <Box sx={{ py: 1, px: 0.5 }}>
                <SalesHeader />
                <Box sx={{ py: 0.5 }}>
                    <TableSection />
                </Box>
                <Box sx={{ py: 0.5 }}>
                    <Footer />
                </Box>
            </Box>
        </CardWrapper>
    );
}

export default SalesReturn;
