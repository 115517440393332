import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';

function QrCodeGenerator({ qrData }) {
    const [qrcodeDataUrl, setQrcodeDataUrl] = useState('');

    const generateQr = (qrData) => {
        if (!qrData) {
            console.error('Invalid QR data:', qrData);
            return;
        }

        const canvas = document.createElement('canvas');
        QRCode.toCanvas(canvas, qrData, (error) => {
            if (error) {
                console.error('Error generating QR code:', error);
            } else {
                const dataUrl = canvas.toDataURL('image/png');
                setQrcodeDataUrl(dataUrl);
            }
        });
    };

    useEffect(() => {
        generateQr(qrData);
    }, [qrData]);

    return <div className="qr-code-container">{qrcodeDataUrl && <img src={qrcodeDataUrl} alt="QR Code" width={'100%'} />}</div>;
}

export default QrCodeGenerator;
