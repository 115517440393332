import { combineReducers } from "redux";

// Reducer imports
import customizationReducer from "./customizationReducer";
import tableDataSlice from "../views/Sales-Sections/salesReturn/slice/tableDataSlice";
import returnHeaderSlice from "../views/Sales-Sections/salesReturn/slice/returnHeaderSlice";
import discAndTaxSlice from "views/Sales-Sections/salesReturn/slice/discAndTaxSlice";
import posHeaderSlice from "views/Sales-Sections/salesPos/slice/posHeaderSlice";
import discAndTaxPosSlice from "views/Sales-Sections/salesPos/slice/discAndTaxPosSlice";
import tableDataPosSlice from "views/Sales-Sections/salesPos/slice/tableDataPosSlice";
import commonSlice from "commonSlice/commonSlice";

//purchase slice
import puchaseHeaderSlice from "views/Purchase-Sections/purchase/slice/puchaseHeaderSlice";
import purchaseDiscAndTaxSlice from "views/Purchase-Sections/purchase/slice/purchaseDiscAndTaxSlice";
import purchaseTableDataSlice from "views/Purchase-Sections/purchase/slice/purchaseTableDataSlice";

//purchaseReturn slice
import puchaseReturnHeaderSlice from "views/Purchase-Sections/purchaseReturn/slice/puchaseReturnHeaderSlice";
import purchaseReturnDiscAndTaxSlice from "views/Purchase-Sections/purchaseReturn/slice/purchaseReturnDiscAndTaxSlice";
import purchaseReturnTableDataSlice from "views/Purchase-Sections/purchaseReturn/slice/purchaseReturnTableDataSlice";
//products
import productDataSlice from "./apis";
// Combine reducers
const reducer = combineReducers({
  common: commonSlice,
  // products

  customization: customizationReducer,

  tableData: tableDataSlice,
  returnHeader: returnHeaderSlice,
  discAndTax: discAndTaxSlice,

  // sales pos reducers
  posHeader: posHeaderSlice,
  discAndTaxPos: discAndTaxPosSlice,
  tableDataPos: tableDataPosSlice,

  //purchase reducers
  purchaseHeader: puchaseHeaderSlice,
  purchaseDiscAndTax: purchaseDiscAndTaxSlice,
  purchaseTableData: purchaseTableDataSlice,

  //purchaseReturn reducers
  purchaseReturnHeader: puchaseReturnHeaderSlice,
  purchaseReturnDiscAndTax: purchaseReturnDiscAndTaxSlice,
  purchaseReturnTableData: purchaseReturnTableDataSlice,

  //allProduct
  ProductsData: productDataSlice,
});

export default reducer;
