export const handleKeyDown = (e) => {
    const isNextButton=e.target.id==="nextButton"
    const isYes=e.target.innerText==="Yes" 
    const isNo=e.target.innerText==="No"
    // const isOk=e.target.innerText==="OK"
    const isHSNCode=e.target.id==="HSNCode"
    const isJump=e.target.id==="Jump"
    const isEnd=e.target.id==="end"



   
    
    console.log(e.target);
    if (e.key==='ArrowLeft' || e.key==="ArrowRight") {
      const nextElement = getNextFocusableElement(e.target,e);
      if(isJump && e.key==='ArrowLeft'){
        document.getElementById('HSNCode').focus()
      }
      else
      if (isHSNCode && e.key==="ArrowRight" ) {
        document.getElementById('Jump').focus()
      }
      else
      if (nextElement) {
        // Focus on the next element
        nextElement.focus();
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
       
      // Find the next focusable element
      if(isEnd){
        document.getElementById('start').focus()
      }
      else
      if (isHSNCode) {
        document.getElementById('Jump').focus()
      }
      else
      if (isYes || isNo) {
        e.target.click()
      }
      else
      if (isNextButton) {
       document.getElementById('ProductName').focus()
       
      }else{
      const nextElement = getNextFocusableElement(e.target,e);
      if (nextElement) {
        // Focus on the next element
        nextElement.focus();
      }
    }
    }
  };
  
  // const getNextFocusableElement = (currentElement) => {
  //   // Get all focusable elements on the page, excluding buttons
  //   const focusableElements = document.querySelectorAll(
  //     'input, select, textarea, [tabindex]:not([tabindex="-1"]), .MuiAutocomplete-input, .MuiPickersBasePicker-pickerView'
  //   );
  
  //   // Filter out buttons from the NodeList
  //   const filteredFocusableElements = Array.from(focusableElements).filter(
  //     (element) => element.tagName.toLowerCase() !== 'button'
  //   );
  
  //   // Find the index of the current element
  //   const currentIndex = filteredFocusableElements.indexOf(currentElement);
  
  //   // Return the next focusable element, or null if not found
  //   return filteredFocusableElements[currentIndex + 1] || null;
  // };
  
  const getNextFocusableElement = (currentElement,e) => {
    // Get all focusable elements on the page, excluding buttons and readonly text fields
    const focusableElements = document.querySelectorAll(
      'input:not([readonly]), select:not([readonly]), textarea:not([readonly]), [tabindex]:not([tabindex="-1"]), .MuiAutocomplete-input:not([readonly]), .MuiPickersBasePicker-pickerView:not([readonly])'
    );
  
    // Filter out buttons and readonly elements from the NodeList
    const filteredFocusableElements = Array.from(focusableElements).filter((element) => {
      // Exclude MUI TextField components with the readOnly prop set to true
      const isTextField = element.tagName.toLowerCase() === 'input' && element.type === 'text';
      const isMuiTextField = element.classList.contains('MuiInputBase-input');
      const isReadOnly = element.readOnly  ;
      return !isTextField || !isMuiTextField || !isReadOnly;
    });
  
    // Find the index of the current element
    const currentIndex = filteredFocusableElements.indexOf(currentElement);
  if (e.key==='ArrowLeft'){
    return filteredFocusableElements[currentIndex - 1] || null;
  }
  
    // Return the next focusable element, or null if not found
    return filteredFocusableElements[currentIndex + 1] || null;
  };
  