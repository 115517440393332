import { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
// import { addItemToTableData } from "views/Sales-Sections/salesReturn/slice/tableDataSlice";
// addItemToTableData
import dayjs from "dayjs";
import BatchWindowPos from "./BatchWindow";
import { useSnackBar } from "context/snackbarContext";
import {
  addItemToTableData,
  updateQtyAndTotal,
} from "../slice/tableDataPosSlice";
import { useEffect } from "react";
import { useCallback } from "react";

function MultiUnitWindowPos({ product, modalClose, batchWise, expiry, setUpperRef}) {
  const dispatch = useDispatch();
  const [openBatch, setOpenBatch] = useState(false);
  const [updatedItem, setUpdatedItem] = useState(null);
  const { showSnackbar } = useSnackBar();
  const tableDataPos = useSelector((state) => state.tableDataPos);
  const [selectedRow, setSelectedRow] = useState(1);
  const [start, setStart] = useState(false);

 

  
  const handleRowClick = (item) => {
    const updatedProduct = {
      ...product,
      ItemC: item.ItemC,
      Rate1: item.rate,
      Cost: item.cost,
      Pack: item.pack,
      qty: product.qty,
      Typ: item.typ,
      exp_date: dayjs().format("DD/MM/YYYY"),
    };
   
    // Calculate the tax amount after the product.Tax1 variable has been initialized.
    
    updatedProduct.taxAmt =
      ((product.qty * updatedProduct.Rate1) / 100) * product.Tax1;
    updatedProduct.total =
      (updatedProduct.Rate1 + updatedProduct.taxAmt) * updatedProduct.qty;
      
    const existingItemIndex = tableDataPos.findIndex(
      (items) => items.ItemC === item.ItemC
    );
    
    const sameProduct = () => {
      const quantity = product.qty;
      const updatedQty = Number(tableDataPos[existingItemIndex].qty) + quantity;
      const updatedTotal =
        ((updatedQty * updatedProduct.Rate1) / 100) * updatedProduct.Tax1 +
        updatedProduct.Rate1 * updatedQty;
      dispatch(
        updateQtyAndTotal({
          index: existingItemIndex,
          qty: updatedQty,
          total: updatedTotal,
          taxAmt:
            ((updatedQty * updatedProduct.Rate1) / 100) * updatedProduct.Tax1,
        })
      );
      showSnackbar(
        "success",
        `Added ${updatedProduct.ItemNameTextField} - ${updatedQty} X ${updatedProduct.Rate1} = ${updatedTotal}`
      );
      modalClose();
    };

    if (batchWise === "YES BATCH") {
      // setUpdatedItem(updatedProduct);
      // setOpenBatch(true);

      if (existingItemIndex !== -1) {
        sameProduct();
      } else {
        setUpdatedItem(updatedProduct);
        setOpenBatch(true);
      }
    } else {
      if (existingItemIndex !== -1) {
        sameProduct();
      } else {
        // const addedOrNot = addItemToTableData.length;
        dispatch(addItemToTableData(updatedProduct));
        modalClose();
        // if (addedOrNot + 1 === addItemToTableData.length) {
        showSnackbar(
          "success",
          `Added ${updatedProduct.ItemNameTextField} - ${updatedProduct.qty} X ${updatedProduct.Rate1} = ${updatedProduct.total}`
        );
        // } else {
        //   showSnackbar("error", `Item not added`);
        // }
      }
    }
  };
  const handleKeyDownGrid = useCallback((e) => {
    console.log('hellogert');
    let nextIndex;
    if (e.key === 'ArrowDown') {
      nextIndex = selectedRow + 1;
    } else if (e.key === 'ArrowUp') {
      nextIndex = selectedRow - 1;
    }
    if (nextIndex > 0 && nextIndex < 4) {
      setSelectedRow(nextIndex);
    }
    if (e.key === 'Enter') {
     if(selectedRow===1){ handleRowClick({
        typ: product.Typ,
        cost: product.Cost,
        rate: product.Rate1,
        pack: 1,
        ItemC: product.ItemC,
      }
    )
    setStart(false)
  
  }
      if (selectedRow===2) {
        handleRowClick({
          typ: product.SecUnit,
          cost: product.SecCostRate,
          rate: product.SecRetailRate,
          pack: product.Pack,
          ItemC: product.SecBarcode,
        })
        setStart(false)
      }
      if (selectedRow===3) {
        handleRowClick({
          typ: product.ThirdUnit,
          cost: product.ThirdCost,
          rate: product.ThirdRate1,
          pack: product.ThirdPack,
          ItemC: product.ThirdBarcode,
        })
        setStart(false)
      }
    }
  });
  
  useEffect(() => {
    if(start){

      document.addEventListener("keydown", handleKeyDownGrid);
      
      return () => {
          document.removeEventListener("keydown", handleKeyDownGrid);
      };
    }
    setStart(true)
}, [handleKeyDownGrid,start]);
  return (
    < >
      {!openBatch ? (
        <>
          {" "}
          <Box sx={{ width: 380, maxWidth: "100%" }} >
            <Box sx={{ py: 1, textAlign: "center" }}>
              <Typography variant="h3">Units</Typography>
            </Box>
            <TableContainer onKeyDown={handleKeyDownGrid}>
              <Table aria-label="simple table" >
                <TableHead sx={{ background: "#2196F3", color: "#fff" }}>
                  <TableRow>
                    <TableCell sx={{ color: "white" }} align="center">
                      Unit
                    </TableCell>
                    <TableCell align="center" sx={{ color: "white" }}>
                      Cost
                    </TableCell>
                    <TableCell align="center" sx={{ color: "white" }}>
                      Rate
                    </TableCell>
                    <TableCell align="center" sx={{ color: "white" }}>
                      Pack
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 },bgcolor:`${selectedRow===1?"grey":"white"}` }}
                    onClick={() =>
                      handleRowClick({
                        typ: product.Typ,
                        cost: product.Cost,
                        rate: product.Rate1,
                        pack: 1,
                        ItemC: product.ItemC,
                      })
                    }
                    
                  >
                    <TableCell component="th" scope="row" align="center">
                      {product.Typ}
                    </TableCell>
                    <TableCell align="center"> {product.Cost}</TableCell>
                    <TableCell align="center"> {product.Rate1}</TableCell>
                    <TableCell align="center">1</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 },bgcolor:`${selectedRow===2?"grey":"white"}` }}
                    onClick={() =>
                      handleRowClick({
                        typ: product.SecUnit,
                        cost: product.SecCostRate,
                        rate: product.SecRetailRate,
                        pack: product.Pack,
                        ItemC: product.SecBarcode,
                      })
                    }
                  >
                    <TableCell component="th" scope="row" align="center">
                      {product.SecUnit}
                    </TableCell>
                    <TableCell align="center"> {product.SecCostRate}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {product.SecRetailRate}
                    </TableCell>
                    <TableCell align="center"> {product.Pack}</TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 },bgcolor:`${selectedRow===3?"grey":"white"}` }}
                    onClick={() =>
                      handleRowClick({
                        typ: product.ThirdUnit,
                        cost: product.ThirdCost,
                        rate: product.ThirdRate1,
                        pack: product.ThirdPack,
                        ItemC: product.ThirdBarcode,
                      })
                    }
                  >
                    <TableCell component="th" scope="row" align="center">
                      {product.ThirdUnit}
                    </TableCell>
                    <TableCell align="center"> {product.ThirdCost}</TableCell>
                    <TableCell align="center"> {product.ThirdRate1}</TableCell>
                    <TableCell align="center"> {product.ThirdPack}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <BatchWindowPos
          product={updatedItem}
          modalClose={modalClose}
          expiry={expiry}
          setUpperRef={setUpperRef}
        />
      )}
    </>
  );
}

export default MultiUnitWindowPos;
MultiUnitWindowPos.propTypes = {
  product: PropTypes.object.isRequired,
  modalClose: PropTypes.func.isRequired,
  batchWise: PropTypes.string.isRequired,
  expiry: PropTypes.string.isRequired,
  setUpperRef:PropTypes.any
};

