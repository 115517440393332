import React, { createContext, useState, useContext } from "react";

const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
  const [dashboardValues, setDashboardValues] = useState({
    TotalSales: "",
    ProductCount: "",
    TotalIncome: "",
  });
  return (
    <CommonContext.Provider
      value={{
        dashboardValues,
        setDashboardValues,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export const useCommonContextValues = () => {
  return useContext(CommonContext);
};
