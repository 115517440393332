import { useRef, useEffect } from "react";
import { Box, Divider, Container, Stack, Grid } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useLocation } from "react-router";
// import axios from "axios";
// import { baseUrl } from "config";
import dayjs from "dayjs";
import QrCodeGenerator from "views/commonComponents/QrCode/QrCodeGenerator";
import { useSelector } from "react-redux";

function ThermalPrintTaxVat() {
  const location = useLocation();
  const { data, tableData, qrData, typ } = location.state;
  console.log(typ);
  console.log(qrData);
  console.log(tableData);
  console.log(data);
  const componentRef = useRef();
  const company = useSelector((state) => state.common.companyInfo);
  const navigate = useNavigate();
  const decimalCount = useSelector((state) => state.common.decimalCount);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // This will be called after the printing is completed
      navigate(-2); // Navigate back to the previous page
    },
  });

  // useEffect(() => {
  //   axios
  //     .get(baseUrl + "/main/getCompanyDetails", { withCredentials: true })
  //     .then((res) => {
  //       setCompany(res.data[0]);
  //     });
  //   // handlePrint();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (company) {
      // this timeout is used to show qr code rendering in print modal
      const timeoutId = setTimeout(() => {
        handlePrint();
      }, 100);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);
  return (
    <div>
      <Box
        width={384}
        ref={componentRef}
        sx={{ background: "white", p: 2, color: "black" }}
      >
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item xs={12} mt={2}>
            <h2 style={{ margin: 0 }}>{company?.Name}</h2>
            <p style={{ margin: 0, fontWeight: 600 }}>{company?.Place} </p>
          </Grid>
          <Grid item xs={12} mt={2}>
            <p style={{ margin: 0, fontWeight: 600 }}>
              VAT No :{company?.tax_no_ar}{" "}
            </p>
            <p style={{ margin: 0, fontWeight: 600 }}>Mob :{company?.Ph} </p>
          </Grid>
          <Grid item xs={12} mt={2}>
            <p style={{ margin: 0, fontWeight: 600 }}>
              Simplified Tax Invoice{" "}
            </p>
            <p style={{ margin: 0, fontWeight: 600 }}>فاتورة ضريبية مبسطة </p>
          </Grid>
        </Grid>
        <Grid
          Container
          sx={{ fontSize: "14px", fontWeight: 600, lineHeight: "22px" }}
        >
          <Divider sx={{ background: "black", p: 0.05 }} />
          <Divider sx={{ background: "black", p: 0.05 }} />
          <Grid item xs={12}>
            <Grid
              Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6}>
                {typ === "SB" || typ === "PB"
                  ? "Inv. No: الفاتورة  "
                  : "Ret.No"}
              </Grid>
              <Grid item xs={6}>
                {typ === "SB" || typ === "PB" ? data.BillNo : data.RetNo}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6}>
                DATE:(تاريخ)
              </Grid>
              <Grid item xs={6}>
                <span>{dayjs(data.BillDate).format("DD/MM/YYYY")}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6}>
                To:
              </Grid>
              <Grid item xs={6}>
                <span>
                  {data.BillPartyName === "3" ? "Cash" : data.BillPartyName}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              Container
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={6}>
                VAT No :
              </Grid>
              <Grid item xs={6}>
                {/* <span>{dayjs(data.BillDate).format("DD/MM/YYYY")}</span> */}
              </Grid>
            </Grid>
          </Grid>

          {/* <p style={{ margin: "1px", fontSize: "12px" }}>
            Customer Name:{" "}
            <span>
              {data.BillPartyName === "3" ? "Cash" : data.BillPartyName}
            </span>
          </p>
          <Container
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <p style={{ margin: "1px", fontSize: "12px" }}>
              State: <span>KERALA</span>
            </p>
            <p style={{ margin: "1px", fontSize: "12px" }}>
              Veh: <span>0</span>
            </p> */}
          {/* </Container> */}
        </Grid>
        <Grid container>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  <div>SI</div>
                  <div>رقم</div>
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                    width: "180px",
                  }}
                >
                  <div>Description</div>
                  <div>البـيـــــان</div>
                </th>
                <th
                  style={{
                    textAlign: "left",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  <div>Qty</div>
                  <div>كيمة</div>
                </th>
                <th
                  style={{
                    textAlign: "center",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                    paddingLeft: "5px",
                  }}
                >
                  <div>Rate</div>
                  <div>السعر</div>
                </th>
                <th
                  style={{
                    textAlign: "right",
                    borderBlock: "dashed 1px #8c8b8b",
                    fontWeight: "normal",
                  }}
                >
                  <div>Total</div>
                  <div>المجموع</div>
                </th>
              </tr>
            </thead>
            <tbody style={{ border: "1px solid black", width: "100%" }}>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "left" }}>{index + 1}</td>
                  <td style={{ textAlign: "left" }}>
                    {item.ItemNameTextField}
                  </td>
                  <td style={{ textAlign: "left" }}>{item.qty}</td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {typ === "PR" || typ === "PB"
                      ? parseFloat(item.Cost).toFixed(decimalCount)
                      : parseFloat(item.Rate1).toFixed(decimalCount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {item.total.toFixed(decimalCount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <div
          style={{ borderBlock: "dashed 0.05px black", width: "100%", mt: 1 }}
        ></div>
        <Grid container mt={2}>
          <Grid item xs={8} sx={{ textAlign: "right", fontWeight: "bold" }}>
            الإجمالي غير شامل الضريبة :
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pr: 1 }}>
            {typ === "PR" || typ === "PB"
              ? (data?.Total - data?.TItTaxAmt).toFixed(decimalCount)
              : typ === "SR"
              ? data?.BillTotalField.toFixed(decimalCount)
              : (data?.BillTotalField - data?.TItTaxAmt).toFixed(decimalCount)}
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "right", fontWeight: "bold" }}>
            ضريبة القيمة المضافة ({company?.tax}%):{" "}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pr: 1 }}>
            {typ === "PR" || typ === "PB"
              ? data.Tax.toFixed(decimalCount)
              : data?.TItTaxAmt.toFixed(decimalCount)}
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "right", fontWeight: "bold" }}>
            الخصم :{" "}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pr: 1 }}>
            {typ === "PR" || typ === "PB"
              ? data.DiscAmt.toFixed(decimalCount)
              : data?.BillDiscAmtField.toFixed(decimalCount)}
          </Grid>
          <Grid item xs={8} sx={{ textAlign: "right", fontWeight: "bold" }}>
            الإجمالي شامل الضريبة :{" "}
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ textAlign: "right", pr: 1, fontWeight: "bold" }}
          >
            {typ === "PR" || typ === "PB"
              ? (data?.Total - data?.TItTaxAmt).toFixed(decimalCount)
              : typ === "SR"
              ? data?.BillNetTotalField.toFixed(decimalCount)
              : (data?.BillTotalField - data?.TItTaxAmt).toFixed(decimalCount)}
          </Grid>
          <Grid item xs={12} mt={2}>
            <Divider sx={{ background: "black", p: 0.05 }} />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ background: "black", p: 0.05 }} />
          </Grid>
          <Container
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "0.4rem",
              paddingTop: "0.4rem",
            }}
          >
            <small style={{ textAlign: "center" }}>Total No. of Items #:</small>
            <small style={{ textAlign: "center" }}>
              <span>{tableData.length}</span>
            </small>
            <small style={{ textAlign: "center" }}># البنود عدد مج</small>
          </Container>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ background: "black", p: 0.19 }} />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="center">
          <Box width={100}>
            <QrCodeGenerator qrData={qrData} />
          </Box>
        </Stack>
        <Grid container sx={{ textAlign: "center", fontWeight: "bold" }}>
          <Grid item xs="12" sx={{ fontSize: "16px" }}>
            شكرا لزيارة مرحبا بكم مرة أخرى
          </Grid>
          <Grid item xs="12" sx={{ fontSize: "12px" }}>
            Thank you, Visit again.
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default ThermalPrintTaxVat;
