// assets
import { IconDashboard, IconShoppingCartPlus, IconCrown } from "@tabler/icons";
import { redirectToMaster } from "config";
import { homeUrl } from "config";

// constant
const icons = { IconDashboard, IconShoppingCartPlus, IconCrown };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard/default",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "addProduct",
      title: "Product",
      type: "item",
      url: "/product",
      icon: icons.IconShoppingCartPlus,
      breadcrumbs: false,
    },
    {
      id: "master",
      title: "Master",
      type: "item",
      url: "/master",
      icon: icons.IconCrown,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
