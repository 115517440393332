import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const purchaseHeaderSlice = createSlice({
    name: 'purchaseHeader',
    initialState: {
        customer: null,
        contact: 0,
        prevNo: 0,
        BatchNo: 0,
        invo: null,
        taxNo: 0,
        vatNo: 0,
        date: dayjs(),
        outlet: null,
        careOf: null,
        editMode: false
    },
    reducers: {
        updateSelected: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        resetState: () => {
            // Reset the state to its initial/default values
            return {
                customer: null,
                contact: 0,
                prevNo: 0,
                BatchNo: 0,
                invo: null,
                taxNo: 0,
                vatNo: 0,
                date: dayjs(),
                outlet: null,
                careOf: null,
                godownWise: false
            };
        }
    }
});

export const { updateSelected, resetState } = purchaseHeaderSlice.actions;
export default purchaseHeaderSlice.reducer;
