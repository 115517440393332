import dashboard from "./dashboard";
import sales from "./sales";
import purchase from "./purchase";
import reports from "./reports";
import accounts from "./accounts";
import others from "./others";
import settings from "./settings";

// ==============================|| MENU ITEMS ||============================== //

const array = [
  "dashboard",
  "sales",
  "purchase",
  "reports",
  "accounts",
  "others",
  // "settings",
];
const menuItems = {
  items: [],
};

const importMap = {
  dashboard,
  sales,
  purchase,
  reports,
  accounts,
  others,
  settings,
};

array.forEach((item) => {
  if (importMap[item]) {
    menuItems.items.push(importMap[item]);
  }
});

export default menuItems;
