import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

import { SnackBarProvider } from "context/snackbarContext";
import { TextFileValuesProvider } from "context/textFileValueContext";
import { CommonContextProvider } from "context/commonContext";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.html5.js";
import { useEffect } from "react";
import { fetchData } from "store/apis";

const App = () => {
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  return (
    <SnackBarProvider>
      <TextFileValuesProvider>
        <CommonContextProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
              <CssBaseline />
              <NavigationScroll>
                <Routes />
              </NavigationScroll>
            </ThemeProvider>
          </StyledEngineProvider>
        </CommonContextProvider>
      </TextFileValuesProvider>
    </SnackBarProvider>
  );
};

export default App;
