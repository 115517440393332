import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
// import axios from "axios";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  addItemToTableData,
  selectedRowOrCurrentRow,
} from "../slice/tableDataPosSlice";
import dayjs from "dayjs";
// import { baseUrl, redirectUrl } from "config";
import { useSnackBar } from "context/snackbarContext";
import axios from "axios";
import { baseUrl } from "config";
import { redirectUrl } from "config";
import { handleKeyDown } from "views/utilities/enterFunctionForPOS";
import { useCallback } from "react";

//--------// APIS_USED_FOR_THIS_COMPONENT //---------//
const getBatch = "/generateBatchData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 2,
};
// function BatchWindowPos({ product, modalClose, expiry, batchData }) {
function BatchWindowPos({ product, modalClose, expiry, setUpperRef }) {
  const dispatch = useDispatch();
  const [batchData, setBatchData] = useState([]);
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackBar();
  const [selectedRow, setSelectedRow] = useState(0);
  const [start, setStart] = useState(false);
  // const [product, setProduct] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          baseUrl + getBatch,
          { item: product?.ItemNameTextField },
          { withCredentials: true }
        );

        if (response.status === 403) redirectUrl();
        if (response.data.length > 0) {
          setBatchData(response.data);
        } else {
          modalClose();
          const currProduct = {
            ...product,
            qty: product.qty,
            taxAmt: ((product.qty * product.Rate1) / 100) * product.Tax1,
            total: product.taxAmt + product.Rate1 * product.qty,
            exp_date: dayjs().format("DD/MM/YYYY"),
          };
          // currProduct.total = currProduct.taxAmt + currProduct.Rate1;

          dispatch(addItemToTableData(currProduct));
          dispatch(
            selectedRowOrCurrentRow({ name: currProduct.ItemNameTextField })
          );
          showSnackbar(
            "success",
            `Added ${currProduct.ItemNameTextField} - ${currProduct.qty} X ${currProduct.Rate1} = ${currProduct.total}`
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
      // try {
      //   if (!batchData) {
      //     modalClose();
      //     const currProduct = {
      //       ...product,
      //       qty: product.qty,
      //       taxAmt: ((product.qty * product.Rate1) / 100) * product.Tax1,
      //       total: product.taxAmt + product.Rate1 * product.qty,
      //       exp_date: dayjs().format("DD/MM/YYYY"),
      //     };
      //     // currProduct.total = currProduct.taxAmt + currProduct.Rate1;

      //     dispatch(addItemToTableData(currProduct));
      //     showSnackbar(
      //       "success",
      //       `Added ${currProduct.ItemNameTextField} - ${currProduct.qty} X ${currProduct.Rate1} = ${currProduct.total}`
      //     );
      //   }
      // } catch (error) {
      //   console.error("Error:", error);
      // }
    };

    fetchData();

    if (setUpperRef) {
      setUpperRef(true);
    }
  }, []);

  const handleRowClick = (data) => {
    // const expDate = data.exp_date.split('-');
    // const day = parseInt(expDate[0]);
    // const month = parseInt(expDate[1]) - 1; // Months are 0-based (0 = January, 1 = February, ...)
    // const year = 2000 + parseInt(expDate[2]);
    // const parsedDate = new Date(year, month, day);
    // const expDateConvert = parsedDate;
    if (expiry === "YES EXPIRY") {
      // if (parsedDate <= new Date()) {
      //     setOpen(true);
      // }
      if (data.exp_date) {
        const batchItem = {
          ...product,
          Rate1: data.price,
          Cost: data.cost,
          Pack: data.pack,
          batch: data.batch_no,
          curr_stock: data.curr_stk,
          qty: product.qty,
          taxAmt: ((product.qty * product.Rate1) / 100) * product.Tax1,
          total: (product.taxAmt + product.Rate1) * product.qty,
          exp_date: data.exp_date,
        };
        dispatch(addItemToTableData(batchItem));
        modalClose();
      }
    } else {
      const batchItem = {
        ...product,
        Rate1: data.price,
        Cost: data.cost,
        Pack: data.pack,
        batch: data.batch_no,
        curr_stock: data.curr_stk,
        qty: product.qty,
        taxAmt: ((product.qty * product.Rate1) / 100) * product.Tax1,
        total: (product.taxAmt + product.Rate1) * product.qty,
        exp_date: dayjs().format("DD/MM/YYYY"),
      };
      dispatch(addItemToTableData(batchItem));

      modalClose();
    }
    setUpperRef(true);
  };

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleKeyDownGrid = useCallback((e) => {
    let nextIndex;
    if (e.key === "ArrowDown") {
      nextIndex = selectedRow + 1;
    } else if (e.key === "ArrowUp") {
      nextIndex = selectedRow - 1;
    }
    if (nextIndex >= 0 && nextIndex < batchData.length) {
      setSelectedRow(nextIndex);
    }
    if (e.key === "Enter") {
      handleRowClick(batchData[selectedRow]);
    }
  });
  useEffect(() => {
    if (start) {
      document.addEventListener("keydown", handleKeyDownGrid);

      return () => {
        document.removeEventListener("keydown", handleKeyDownGrid);
      };
    }
    setStart(true);
  }, [handleKeyDownGrid, start]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Warning
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="h3"
            sx={{ mt: 2 }}
            color={"error"}
          >
            This item is expired
          </Typography>
          <Stack direction={"row"} justifyContent={"end"}>
            <Button onClick={handleClose} variant="contained" color="info">
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Box sx={{ width: 580, maxWidth: "100%" }}>
        <Box sx={{ py: 1, textAlign: "center" }}>
          <Typography variant="h3">Batch</Typography>
        </Box>
        <TableContainer onKeyDown={handleKeyDownGrid}>
          <Table aria-label="simple table">
            <TableHead sx={{ background: "#2196F3", color: "#fff" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }} align="center">
                  Batch
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Cost
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Stock
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Price
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Pack
                </TableCell>
                <TableCell align="center" sx={{ color: "white" }}>
                  Exp date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody onKeyDown={handleKeyDownGrid}>
              {batchData.map((batch, index) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    bgcolor: `${selectedRow === index ? "grey" : "white"}`,
                  }}
                  key={index}
                  onClick={() => handleRowClick(batch)}
                  id={index === batchData.length - 1 ? "firstData" : null}
                  onKeyDown={handleKeyDown}
                >
                  <TableCell component="th" scope="row" align="center">
                    {batch.batch_no}
                  </TableCell>
                  <TableCell align="center">{batch.cost}</TableCell>
                  <TableCell align="center">{batch.curr_stk}</TableCell>
                  <TableCell align="center">{batch.price}</TableCell>
                  <TableCell align="center">{batch.pack}</TableCell>
                  <TableCell align="center">{batch.exp_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default BatchWindowPos;
BatchWindowPos.propTypes = {
  product: PropTypes.object.isRequired,
  expiry: PropTypes.string.isRequired,
  modalClose: PropTypes.func.isRequired,
  setUpperRef: PropTypes.any,
};
